<template>
  <v-col class="modads mt-1">
    <v-col class="shades transparent pa-6 pb-0">
      <v-row  class="px-0">
        <v-col cols="12" md="12">
          <h2 class="font-weight-bold headline hidden-sm-and-down">가맹점의 정보를<br>설정해주세요.</h2>
        </v-col>
        <v-col md="6">
          <div class="body-1 font-weight-bold mt-8 mb-2">위치<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-row>
            <v-col md="6">
              <v-select v-model="form.local.region"
                        :items="Object.entries((option || {}).local || {}).map(([k, v]) => ({text: k, value: k})) || []"
                        item-text="text"
                        item-value="value"
                        label="지역"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="checkDone"
                        class="mr-2"></v-select>
            </v-col>
            <v-col md="6">
              <v-select v-model="form.local.code"
                        :items="Object.entries(((option || {}).local || {})[form.local.region] || {}).map(([k, v]) => ({text: v, value: k})) || []"
                        item-text="text"
                        item-value="value"
                        label="상권"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="checkDone"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white pa-6 mt-2" >
      <v-row  class="px-0">
        <v-col cols="12" md="10">
          <div class="body-1 font-weight-bold mb-2">매장 이름<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-text-field v-model="form.name" name="name" type="text"
                        :rules="[errors.first('name') || !errors.has('name')]"
                        @input="checkDone" :disabled="(adsData.naver || {}).available === 0 || (adsData.insta || {}).available === 0"
                        single-line outlined solo flat required></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" >
          <div class="body-1 font-weight-bold mb-2">매장 주소<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-row >
            <v-col cols="8" class="py-1">
              <v-text-field v-model="form.address" name="address" type="text"
                            :rules="[errors.first('address') || !errors.has('address')]"
                            placeholder="주소 검색 버튼을 클릭해주세요."
                            @input="checkDone"
                            readonly hide-details
                            single-line outlined solo-inverted flat required></v-text-field>
            </v-col>
            <v-col cols="4" class="pl-4 py-1">
              <v-menu v-model="daumMap" absolute>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="rouge" class="ma-0" outlined style="width: 92px; height: 40px">주소 검색</v-btn>
                </template>
                <vue-daum-postcode @complete="form.address = $event.address; daumMap = false" style="max-height: 500px; overflow: scroll;"></vue-daum-postcode>
              </v-menu>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="form.addressDetail" name="addressDetail" type="text"
                            :rules="[errors.first('addressDetail') || !errors.has('addressDetail')]"
                            placeholder="상세 주소를 입력해주세요."
                            @input="checkDone"
                            single-line outlined solo flat></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="body-1 font-weight-bold mb-2">매장 연락처<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-text-field v-model="form.contacts" name="contacts" type="text"
                        :rules="[errors.first('contacts') || !errors.has('contacts')]"
                        placeholder="예) 02-1234-1234"
                        @input="checkDone"
                        single-line outlined solo flat></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="body-1 font-weight-bold mb-2">매장 영업시간<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-text-field v-model="form.time" name="time" type="text"
                        :rules="[errors.first('time') || !errors.has('time')]"
                        hint="월-토 11:00 - 24:00, 주말 휴무"
                        persistent-hint
                        @input="checkDone"
                        single-line outlined solo flat></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="body-1 font-weight-bold mb-2">인스타그램 공식 계정</div>
          <div class="body-1 mb-2">공식 계정이 있는 경우 인스타그램 아이디만 입력해주세요. (@제외)</div>
          <v-text-field v-model="form.instaOfficial" name="instaOfficial" type="text"
                        :rules="[errors.first('instaOfficial') || !errors.has('instaOfficial')]"
                        @input="checkDone"
                        single-line outlined solo flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
  export default {
    name: 'Page2',
    components: {
      VueDaumPostcode: () => import('@/components/shared/vue-daum-postcode')
    },
    props: ['adsData', 'option'],
    data: function () {
      return {
        form: {
          local: {
            code: (this.adsData.local || {}).code || '',
            region: (this.adsData.local || {}).region || ''
          },
          name: this.adsData.name || '',
          address: this.adsData.address || '',
          addressDetail: this.adsData.addressDetail || '',
          contacts: this.adsData.contacts || '',
          time: this.adsData.time || '',
          instaOfficial: this.adsData.instaOfficial || ''
        },
        daumMap: false
      }
    },
    watch: {
      adsData(value) {
        this.form = {
          local: {
            code: (value.local || {}).code || '',
            region: (value.local || {}).region || ''
          },
          name: value.name || '',
          address: value.address || '',
          addressDetail: this.adsData.addressDetail || '',
          contacts: value.contacts || '',
          time: value.time || '',
          instaOfficial: value.instaOfficial || ''
        };
      }
    },
    methods: {
      checkDone() {
        this.$validator.validateAll().then(success => {
          if (success) {
            // if(!this._.isEmpty(this.form.local.region) && !this._.isEmpty(this.form.local.code)) {
            // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
            this.$emit('updateEvent', this.form);
            this.$store.commit('setDirty', true);
            // }
          }
        });
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '../../../../assets/scss/custom';

  ::v-deep .v-list-item {
    font-size: 14px;
  }
</style>